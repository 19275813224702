import $ from "jquery";

$(function () {
	function toggleMobileNav() {
		$(".pri-nav .multi > a").click(function (e) {
			if (1280 > $(window).width()) {
				e.preventDefault();
				var $parent = $(this).parent(".pri-nav .multi");
				$parent.toggleClass("active");
				$(".submenu", $parent).fadeToggle();
			}
		});

		$(".pri-nav .multi").each(function () {
			var link = $("a", $(this)).attr("href");
			$("ul", $(this)).append(
				'<li class="submenu__item"><a href="' +
					link +
					'" class="submenu__link">View All</a></li>'
			);
		});
	}

	toggleMobileNav();
});
