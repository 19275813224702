import $ from "jquery";

$(function () {
	function modContentImg() {
		gsap.registerPlugin(ScrollTrigger);

		gsap.utils.toArray(".mod-content-img__split").forEach(function (item) {
			var content = item.querySelector(".mod-content-img__split .content");
			var image = item.querySelector(".mod-content-img__split .image");

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "100%-=250px bottom",
					end: "top top",
				},
			});

			tl.from(content, {
				opacity: 0,
				y: 100,
			});

			tl.from(image, {
				opacity: 0,
				y: 100,
			});

			tl.to(content, {
				duration: 1,
				opacity: 1,
				y: 0,
			});

			tl.to(
				image,
				{
					duration: 1,
					opacity: 1,
					y: 0,
				},
				"-=0.8"
			);
		});
	}

	modContentImg();
});
