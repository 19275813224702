import $ from "jquery";

$(function () {
	function teamCarousel() {
		var mySwiper = new Swiper(".carousel-team", {
			containerModifierClass: "carousel-team-",
			slideClass: "slide",
			slideActiveClass: "active",
			wrapperClass: "wrapper",
			slidesPerView: 1,
			parallax: true,
			effect: "fade",

			navigation: {
				nextEl: ".btn-next",
				prevEl: ".btn-prev",
				disabledClass: "btn-disabled",
			},
		});
	}

	if ($(".carousel-team")[0]) {
		teamCarousel();
	}
});
