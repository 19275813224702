import $ from "jquery";

$(function () {
	function toggleNav() {
		$(".nav-btn").click(function (e) {
			e.preventDefault();
			$(this).toggleClass("active");
			$(".nav").toggleClass("flex");
		});
	}

	toggleNav();
});
