import $ from "jquery";

$(function () {

		function additionalSpacing() {
			if (!$("body").hasClass("page-child")) {
				$(".mod-banner").next().addClass("js-add");
			}
		}

		additionalSpacing();
	
});
