import $ from "jquery";

$(function () {

	function clientCarousel() {
		var mySwiper = new Swiper(".carousel-client", {
			// Optional parameters
			containerModifierClass: "carousel-client-",
			slideClass: "slide",
			wrapperClass: "wrapper",
			slidesPerView: 1,
			breakpoints: {
				480: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 5,
				},
			},

			pagination: {
				el: ".pagination",
				type: "bullets",
				bulletClass: "dots",
				bulletActiveClass: "active",
			},

			navigation: {
				nextEl: ".btn-next",
				prevEl: ".btn-prev",
				disabledClass: "btn-disabled",
			},
		});
	}

	if ($(".carousel-client")[0]) {
		clientCarousel();
	}
	
});
