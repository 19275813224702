import $ from "jquery";

$(function () {
	function ajaxPostFilter() {
		var almIsAnimating = false;

		function filterClick(event) {
			document.querySelector(".js-filter__list").classList.add("active");

			var parent = this.parentNode;

			if (parent.classList.contains("active") && !almIsAnimating) {
				return false;
			}

			almIsAnimating = true;

			var active = document.querySelector(".js-filter__item.active");

			if (active) {
				active.classList.remove("active");
			}

			parent.classList.add("active");

			var transition = "fade";
			var speed = 250;
			var data = this.dataset;

			ajaxloadmore.filter(transition, speed, data);
		}

		var filterButtons = document.querySelectorAll(".js-filter__item a");
		if (filterButtons) {
			[].forEach.call(filterButtons, function (button) {
				button.addEventListener("click", filterClick);
			});
		}

		window.almFilterComplete = function () {
			almIsAnimating = false;
		};

		var anchors = document.getElementsByTagName("a");

		for (var i = 0; i < anchors.length; i++) {
			if ("js-filter__link" == anchors[i].className) {
				anchors[i].setAttribute("onclick", "return false");
			}
		}
	}

	ajaxPostFilter();
});
