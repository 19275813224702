import $ from "jquery";

$(function () {
	function hoverDesktopNav() {
		if (1280 < $(window).width()) {
			gsap.set(".pri-nav .pri-nav__item .submenu", { autoAlpha: 0 });
			gsap.set(".nav__right .placeholder", { autoAlpha: 0 });

			var $item = $(".pri-nav .pri-nav__link");
			var $image = $(".nav__right .placeholder");

			$item.on("mouseenter", function (e) {
				var $thisItem = $(this).parent();
				var $imageItem = $(this).parent().attr("id");

				var $singleItem = $(this).parent().hasClass("single");
				var $multiItem = $(this).parent().hasClass("multi");

				if ($singleItem) {
					gsap.to($imageItem, { autoAlpha: 1 });
					gsap.to($image.not($imageItem), { autoAlpha: 0 });
					gsap.to($($item).not($thisItem).next(), { autoAlpha: 0 });
				}

				if ($multiItem) {
					gsap.to($(".nav__right"), { className: "nav__right bg-ltblue" });
					gsap.to($($item).not($thisItem).next(), { autoAlpha: 0 });
					gsap.to($($thisItem).find(".submenu"), { autoAlpha: 1 });
					gsap.to($image.not($imageItem), { autoAlpha: 0 });
				}
			});
		}
	}

	hoverDesktopNav();
});
