import $ from "jquery";

$(function () {
	$(".btn--modal").on("click", function (event) {
		event.preventDefault();
		var btnId = $(this).attr("id");
		var modalId = btnId.replace("btn--", "");
		console.log(modalId);
		$("#" + modalId)
			.fadeIn()
			.css("display", "flex");
		$("body").css("overflow-y", "hidden");
	});
	$(".close-btn").on("click", function () {
		$(".mod-hubspot").fadeOut();
		$("body").css("overflow-y", "scroll");
	});
});
