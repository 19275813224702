import $ from "jquery";

$(function () {
	function ctaAnimation() {
		if (1023 < $(window).width()) {
			gsap.set(".mod-cta__v1 .cta .cta__item", { autoAlpha: 0.2 });

			gsap.set(".mod-cta__v1 .image .placeholder", { autoAlpha: 0 });
			gsap.set(".mod-cta__v1 .image #default", { autoAlpha: 1 });

			var $item = $(".mod-cta__v1 .cta .cta__item");
			var $image = $(".mod-cta__v1 .image .placeholder");

			$item.on("mouseenter", function (e) {
				var $thisItem = $(this);
				var $imageItem = $(this).attr("id");

				gsap.to($thisItem, 1, { autoAlpha: 1 });
				gsap.to($item.not($thisItem), 0.5, { autoAlpha: 0.2 });

				gsap.to($imageItem, 1, { autoAlpha: 1 });
				gsap.to($image.not($imageItem), 0.5, { autoAlpha: 0 });
			});
		}
	}

	ctaAnimation();
});
